/* Add this CSS to your existing CSS or a separate CSS file */
.Ecommerce-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    gap: 5em;
    max-width: 90%;
    margin: 0 auto;
    position: relative;
    z-index: 3;
  }
  
  .contEcommerce {
      flex: 1 1;
      margin: 10px;
      width: 30%;
      padding: 20px;
      background: linear-gradient(135deg, rgb(29 170 148 / 16%), rgb(39 46 54 / 32%));
      color: #fff;
      border-radius: 8px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      transition: transform 0.3s, filter 0.3s;
    }
    
    .contEcommerce:hover {
      transform: scale(1.05);
      filter: none;
    }
    
    section:hover .contEcommerce:not(:hover) {
      filter: blur(4px);
    }
    
    .contEcommerce h2 {
      font-size: 24px;
      margin-bottom: 10px;
    }
    
    .contEcommerce p {
      font-size: 16px;
      margin-bottom: 10px;
    }

    h1.ecTitile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;
        font-size: 2em;
    }
    h1.ecTitile span {
        font-weight: bold;
        font-size: .4em;
    }


.ecommerce-card {
    background: #2d2c2ad9;
    color: #fff;
    border-radius: 10px;
    border: solid 1px #1daa94;
    padding: 20px;
    margin: 15px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(2px);
    transition: transform 0.3s ease;
    width: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .ecommerce-card:hover {
    transform: translateY(-5px);
  }
  
  .ecommerce-card img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  
  .ecommerce-card h3 {
    font-weight: lighter;
    font-size: 1.2em;
    margin: 10px 0;
    text-transform: uppercase;
  }
  
  .ecommerce-card h4 {
    text-transform: uppercase;
  }
  
  .ecommerce-card p {
    font-size: 0.9em;
    margin: 5px 0;
  }
  
  .ecommerce-card a.cv-download {
    display: inline-block;
    padding: 10px 20px;
    background: linear-gradient(135deg, #1daa94, #2e9f9a, #368f99, #3a7e92, #386d89, #375d7f);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    border-radius: 6px;
    font-weight: bold;
    text-decoration: none;
    position: relative;
    color: white;
    width: 100%;
    text-align: center;
  }
  .custom-module-container .neon-effect {
    display: flex;
    justify-content: center;
    align-items: center;
}
  .ecommerce-card a.cv-download::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, #1daa94, #2e9f9a, #368f99, #3a7e92, #386d89, #375d7f);
    z-index: -1;
  }
  
  .ecommerce-card .contact {
    display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 2em;
      margin-top: 2em;
  }
  .ecommerce-card .contact-icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 2em;
  }
  
  .ecommerce-card .social-media{
    display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 2em;
  }
  .custom-module-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
    
    @media (max-width: 767px) {
    .contEcommerce {
      width: 95% !important;
      margin: 0 auto;
    }
    .Ecommerce-section {
      gap: 1em;
    }
  }
  .BannerEcom-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  .BannerEcom-container {
    display: flex;
    border-radius: 15px;
    -webkit-filter: drop-shadow(1px 10px 5px rgba(0,0,0,.2));
    filter: drop-shadow(1px 10px 5px rgba(0,0,0,.2));
    margin: 0 auto;
    position: relative!important;
    width: 90%!important;
    overflow: hidden;
    height: 500px;
}
div#animationWrapper,
.BannerEcom-content {
  width: 50%;
}

.Ecommerce-section .App{
  padding-top: 0em;
}
.App svg {
  width: 80vh;
  height: 80vh;
  perspective: 50px;
}
.App circle{
  stroke: #1daa94;
  stroke-width: 5px;
  fill:none;
}
.App .equator {
  transform:  rotateZ(-20deg)  rotateX(125deg)
}

.App .meridian{  
  animation: turn 1s infinite linear;
  transform:  rotateZ(-20deg) rotateX(35deg) rotateY(var(--start))
}
@keyframes turn{
  100% {
    transform:  rotateZ(-20deg) rotateX(35deg) rotateY(var(--end))
  }
}


.services-section {
    padding: 6rem 0%;
}

.title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.service {
  display: flex;
  flex-direction: row-reverse;
  gap: 4em;
  align-items: center;
  margin: 3em 0em;
}

.service-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.service-list {
    margin-bottom: 1.5rem;
}

.service-list li {
    margin: 0.2rem 0;
}

.illustration {
    text-align: center;
    margin-bottom: 1rem;
    max-width: 50%;
}
.illustration img{
  width: 100%;
  background: linear-gradient(135deg, rgb(29 170 148 / 16%), rgb(39 46 54 / 32%));
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, filter 0.3s;
}

#web-design > div:nth-child(5) {
  flex-direction: row;
}


.inclusive-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; 
  gap: 20px; 
  padding: 0;
  margin-bottom: 13em;
}

.inclusive-list svg{
  height: 30px;
  width: 30px;
  color: rgb(29, 170, 148);
}


.inclusive-list li {
  display: flex;
  align-items: center;
  gap: 10px; 
  transition: background 0.6s ease; 
  padding: 1em;
}

span.item-title {
  font-weight: 900;
}
.inclusive-list li:hover{
  background: linear-gradient(135deg, 
    rgba(55, 93, 127, 0.2), 
    rgba(56, 109, 137, 0.2), 
    rgba(58, 126, 146, 0.2), 
    rgba(54, 143, 153, 0.2), 
    rgba(46, 159, 154, 0.2), 
    rgba(29, 170, 148, 0.2)
  );
  border-radius: 4px;
}

@media (max-width: 767px) {
.BannerEcom-container {
  width: 99% !important;
}
.App svg {
  width: 50vh;
  height: 70vh;
  perspective: 50px;
}
.App h2 {
  padding: 0px;
}
.inclusive-list svg {
  height: 30px;
  width: 30px;
}
.service {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
  margin: 1em 0em;
}
#web-design > div:nth-child(5) {
  flex-direction: column;
}
.inclusive-list {
  display: table-row;
}
.illustration {
  max-width: 90%;
}
.flex {
  display: flex;
  flex-direction: column;
}
.ecommerce-card {
  width: 85%;
}
.neonButton {
  display: block;
}
}