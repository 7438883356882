.header {
    position: absolute;
    width: 100%;
    transition: background-color 0.5s;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0px;
    z-index: 10;
  }
  
  .header.scrolled {
    position: fixed;
    top: 0;
    background-color: #00000087; /* Change to desired color */
    backdrop-filter: blur(7px); /* Blurring content behind */
    -webkit-backdrop-filter: blur(7px); /* For Safari */
  }
  

.navigation ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 20px;
  }
  
  .navigation a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
    padding: 3px 9px;
  }
  
  .navigation a:hover {
    background: linear-gradient(135deg, #1daa94, #2e9f9a, #368f99, #3a7e92, #386d89, #375d7f);
    color: white; /* Adjust hover color as needed */
    border-radius: 4px;
  }

  .dropdown a:hover {
    background: none;
  }
  
  .dropdown a:hover,
  .dropdown a:hover > svg {
    color: rgb(29, 170, 148);
  }
  .navigation ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  
  .navigation > ul > li {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  
  .navigation li a {
    text-decoration: none;
    /* Additional styling */
  }
  
  .navigation ul ul {
    display: none;
    position: absolute;
    top: 100%;
    /* Additional styling */
  }
  
  .navigation ul li:hover > ul {
    display: block;
    /* Additional styling */
  }
  

  header.header {
    display: flex;
    justify-content: space-around;
    align-items: center;
}  
.dropdown li{
  padding-bottom: 2em;
}
/* Mobile-specific styles */
.hamburger-menu {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  }
  
  .line {
    width: 30px;
    height: 3px;
    background: #fff;
  }
  
  .navigation.open {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .close-button {
    font-size: 24px;
  }
  ul.dropdown {
    background: #2d2c2ad9;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    text-align: left;
    padding: 30px 0px 0px 30px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(2px);
    height: auto;
    border-radius: 6px;
    width: 15em;
  }
  .modal {
    position: absolute;
    top: 74px;
    background-color: #000000ad;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
button.close-button {
  float: right;
  color: white;
  background: rgb(29, 170, 148);
  border-radius: 25px;
  border-color: rgb(29, 170, 148);
}
svg.svg-inline--fa.fa-globe {
    color: white;
}

.hamburger-menu .line {
    transition: all 0.3s;
}

.hamburger-menu.open .line:nth-child(1) {
    transform: translateY(5px) rotate(45deg);
}

.hamburger-menu.open .line:nth-child(2) {
    opacity: 0;
}

.hamburger-menu.open .line:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
}

  
  @media (max-width: 767px) {
    .desktop {
      display: none;
    }
  
    .mobile {
      display: flex;
      align-items: center;
    }
    .navigation {
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
    .modal {
      top: 0px;
  }
    .navigation.open {
      display: flex;
      z-index: 90;
      margin-top: 4.5em;
      justify-content: flex-start;
      align-items: flex-end;
      height: 20em;

      width: 70%;
  }
  .navigation ul {
    flex-direction: column;
    align-items: flex-end;
    padding: 2em;
  }
    .hamburger-menu {
        display: flex;
    }
    .dropdown{
      display: none !important;
    }
  }
  