/* Add this CSS to your existing CSS or a separate CSS file */
.intro-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: 5em;
  max-width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}

.contIntro {
  display: flex; /* Turn .contIntro into a flex container */
  flex-direction: column; /* Align children vertically; use 'row' for horizontal alignment */
  justify-content: center; /* Center children along the main axis (vertical if flex-direction is column) */
  align-items: center; /* Center children along the cross axis (horizontal if flex-direction is column) */
  flex: 1 1;
  margin: 10px;
  width: 30%;
  padding: 20px;
  background: linear-gradient(135deg, rgb(29 170 148 / 16%), rgb(39 46 54 / 32%));
 
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s, filter 0.3s;
  }
  
  .contIntro:hover {
    transform: scale(1.05);
    filter: none;
  }
  
  section:hover .contIntro:not(:hover) {
    filter: blur(4px);
  }
  
  .contIntro h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .contIntro p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  @media (max-width: 767px) {
  .contIntro {
    width: 95% !important;
    margin: 0 auto;
  }
  .intro-section {
    gap: 1em;
  }
}