/* src/Footer.css */

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: white;
    max-width: 1440px;
    margin: 0 auto;
}

.logo-section img {
    height: 150px;
    /* mix-blend-mode: multiply;
    filter: contrast(1); */
}

.navigation-section a,
.social-media-section a {
    margin: 0 10px;
    text-decoration: none;
    color: white;
}

.social-media-section img {
    height: 25px;
    margin: 0 5px;
}
address a,
address {
    color: #fff;
    text-align: center;
    font-size: 12px;
    width: 90%;
    margin: 0 auto;
}
@media (max-width: 767px) {

.footer {
    display: flex;
    flex-direction: column-reverse;
    gap: 3em;
    padding-top: 3em;
}
.navigation-section a {
    width: 33%;
    text-align: left;
    margin: 0 auto;
}
.navigation-section.desktop {
    display: none;
}
.navigation-section {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: center;
    text-align: left;
    gap: 3em;
    font-size: 14px;
}
.footer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-top: 1em;
}
 div.navigation-section > div{
    width: 100%;
 }
}