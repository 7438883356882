@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
/* PartnersCarousel.css */

.partners-carousel {
  overflow: hidden;
  position: relative;
  padding-top: 4em;
}

.carousel-wrapper {
  overflow: hidden;
  white-space: nowrap;
}

.carousel-content {
  display: inline-flex;
  animation: scroll 30s linear infinite;
}

.carousel-item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  white-space: normal;
}

.logo {
  width: 150px;
  height: auto; /* Maintain aspect ratio */
}

.description {
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: center;
}

.carousel-item:hover .description {
  opacity: 1;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(44,41,38);
background: linear-gradient(0deg, rgba(44,41,38,1) 0%, rgba(0,0,0,1) 80%);
height: 100%;
}
.h1{
  font-weight: lighter;
}
h1{
  font-weight: lighter;
}
h2{
  font-size: 1em;
  font-weight: lighter;
}
code {
  font-family: 'Montserrat', sans-serif;
}
.banner-container {
  position: relative;
}
a{
  text-decoration: none;
}
.banner-content {
  position: absolute;
  top: 40%;
  left: 10%;
  color: #fff; /* Adjust color based on your Vanta effect */
}

.banner-form {
  transform: translateY(100%);
  opacity: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: transform 0.7s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: 0.9s
}

.banner-container .banner-form.visible {
  transform: translateY(0);
  opacity: 1;
  position: absolute;
  top: 3em;
  right: 15em;
  width: auto;
}
form {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  border-radius: 10px;
  border-width: 2px;
  border: solid 1px #1daa94;

  top: 0px;
  left: -124px;
  background: #2d2c2ad9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  text-align: left;
  padding: 20px;
  backdrop-filter: blur(3px); /* Blurring content behind */
  -webkit-backdrop-filter: blur(2px); /* For Safari */
}
.neonButton {
  padding: 10px 20px;
  background: #1daa94;
  border: none;
  color: #fff;
  border-radius: 6px;
  font-weight: bold;
}
.word-change {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.word-visible {
  opacity: 1;
}
.banner-content span{
  font-weight: bold;
}

/* neon-effect.css */

.neon-effect {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background: linear-gradient(135deg, #1daa94, #2e9f9a, #368f99, #3a7e92, #386d89, #375d7f);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  z-index: 1;
}

.neon-effect::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #1daa94, #2e9f9a, #368f99, #3a7e92, #386d89, #375d7f);
  z-index: -1;
  filter: blur(10px);
}


canvas.vanta-canvas{
  width: 90% !important;
  margin: 0 auto;
  position: relative !important;
  border-radius: 15px;
  filter: drop-shadow(1px 10px 5px rgba(0,0,0,.20));
  
}

.s-page-1.s-section-1.s-section {
  height: 675px;
}
/* NeonSeparator.css */



.neonSeparator {
  width: 100%;
  height: 4px;
  background-color: rgba(29, 170, 148, 0.2);
  position: relative;
  overflow: hidden;
  animation: flicker 1.5s infinite alternate; 
}

.consultant-card {
  background: #2d2c2ad9;
  color: #fff;
  border-radius: 10px;
  border: solid 1px #1daa94;
  padding: 20px;
  margin: 15px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(2px);
  transition: transform 0.3s ease;
  min-width: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.consultant-card:hover {
  transform: translateY(-5px);
}

.consultant-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.consultant-card h3 {
  font-weight: lighter;
  font-size: 1.2em;
  margin: 10px 0;
  text-transform: uppercase;
}

.consultant-card h4 {
  text-transform: uppercase;
}

.consultant-card p {
  font-size: 0.9em;
  margin: 5px 0;
}

.consultant-card a.cv-download {
  display: inline-block;
  padding: 10px 20px;
  background: linear-gradient(135deg, #1daa94, #2e9f9a, #368f99, #3a7e92, #386d89, #375d7f);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  border-radius: 6px;
  font-weight: bold;
  text-decoration: none;
  position: relative;
  color: white;
}

.consultant-card a.cv-download::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #1daa94, #2e9f9a, #368f99, #3a7e92, #386d89, #375d7f);
  z-index: -1;
}

.consultant-card .contact {
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 2em;
    margin-top: 2em;
}
.consultant-card .contact-icons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2em;
}

.consultant-card .social-media{
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 2em;
}

.consultants-list {
  display: flex;
  justify-content: space-around;
  padding: 30px;
  flex-direction: row;
  align-content: stretch; /* Change this */
  align-items: stretch; /* Change this */
  flex-wrap: nowrap;
  
}

svg.svg-inline--fa {
  color: white;
}
.App{
  width: 100%;
  padding-top: 10em;
  color: white;
  max-width: 90%;
  margin: 0 auto;
}
.flex {
  display: flex;
}

.mobile{
  display: none;
}
.desktop{
  display:block;
}
.tablet{
  display: none;
}
@media (max-width: 767px) {
  .mobile{
  display: block;
}
  .desktop{
  display:none;
}
.tablet{
  display: none;
}
.App{
  max-width: 99%;
}
canvas.vanta-canvas {
  width: 99% !important;
  height: 100% !important;
}
.banner-container .banner-form.visible {
  top: 6em;
  right: 0;
}
.banner-container {
  height: 800px;
}
.consultant-card {
  margin: 10px;
  padding: 15px;
}
.banner-form.visible {
  z-index: 2;
}
form {
width: 80% !important;
}
.consultants-list {
display: flex !important;
flex-direction: column;
}
.consultant-card {
width: 85%;
}
.consultants-list {
display: flex;
flex-direction: column;
}
.consultant-card .contact{
  flex-direction: column !important;
}
.App h1,
.App h2 {
padding: 20px;
}
.ReactModal__Content.ReactModal__Content--after-open {
  inset: 0 !important;
  top: 2em !important;
  left: 1.2em !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 10;
}
}
.maxW {
  max-width: 1440px;
  margin: 0 auto;
  color: white;
}
.maxW .services-section{
padding: 0;
}
@media screen and (min-width: 768px) and (max-width: 1280px){
  .tablet{
  display: block;
}
    .mobile{
  display: none;
}
    .desktop{
  display:block;
}
}



@keyframes flicker {
  0%, 18%, 22%, 25%, 53%, 57%, 100% {
    box-shadow:
      0 0 7px #fff,
      0 0 10px #fff,
      0 0 21px #fff,
      0 0 42px #0fa,
      0 0 82px #0fa,
      0 0 92px #0fa,
      0 0 102px #0fa,
      0 0 151px #0fa;
  }
  20%, 24%, 55% {       
    box-shadow: none;
  }
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}