.containerGPT {
    background: #2d2c2ad9;
    color: #fff;
    border-radius: 10px;
    border: solid 1px #1daa94;
    padding: 20px;
    margin: 15px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(2px);
    transition: transform 0.3s ease;
}

.containerGPT label {
    display: block;
    margin: 10px 0 5px;
}

.containerGPT input, .containerGPT textarea, .containerGPT select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #1daa94;
    margin-bottom: 10px;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
}

.containerGPT button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(135deg, #1daa94, #2e9f9a, #368f99, #3a7e92, #386d89, #375d7f);
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.containerGPT button:disabled {
    background: rgba(29, 170, 148, 0.2);
    color: #aaa;
}

.containerGPT .neon-effect button:disabled::before {
    background: rgba(29, 170, 148, 0.2);
    filter: blur(0px);
}

.containerGPT .neon-effect button:disabled {
    background: none;
    color: #aaa;
}

.containerGPT p {
    color: #fff;
}
